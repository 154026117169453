
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ExercisePreviewModal,
  ExerciseTile,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  PageContent,
  Paginator,
  RecursionTree,
  SearchBar,
  DatabaseSortSelect
} from '@/shared/components';
import { EMPTY_TEST, GtPageTypes, ROLES, SEARCH_MODES } from '@/config';
import {
  IS_DEBUG_MODE,
  SET_CATEGORY_KEY,
  SET_DEFAULTS,
  SET_PAGE_ID,
  USER_NOTIFY,
  FILTER_EXERCISES
} from '@/store/list';
import { Exercise, StructureStatus, StructureStatusName, TestModel, TestTypeIdEnum } from '@/models';
import { mixins } from 'vue-class-component';
import ExerciseListMixin from '@/mixins/ExerciseListMixin';
import { deepClone } from '@/helpers/object-manipulation';
import { MenuModel } from '@/store/layout-store/types';
import { EXERCISE_COPY } from '@/api/eob/endpoints';
import {
  NeRadioButton,
  NeButton,
  NeIcon
} from '@ne/ne-vue2-lib';
import { DOWNLOAD_PDF_URI } from '@/api/generator/endpoints';
import { LoadingStatus, LoadingStatusName } from '@/shared/models/loading';
import { RouteName } from '@/router/models';
import { ExercisesFilters, ExerciseToAdd, SortData } from '@/models/exercises';
import { CLogLevel } from '@/models/gtm';
import { isSuperUser, isTeacherGroup } from '@/helpers/roles';
import FiltersNew from '@/shared/components/FiltersNew/FiltersNew.vue';
import { Route } from 'vue-router';
import { GtMenu } from '@/views/Generator/components';
import { TreeNode } from '@/models/structure-mapping';

const AppStore = namespace('AppStore');
const LayoutStore = namespace('LayoutStore');
const ExerciseStore = namespace('ExerciseStore');

const defaultSortKey = 'dateNewest';
const defaultPage = 1;

Component.registerHooks(['beforeRouteEnter']);

@Component({
  name: 'ExerciseDatabase',
  components: {
    ExercisePreviewModal,
    ExerciseTile,
    FiltersNew,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
    NeButton,
    NeIcon,
    NeRadioButton,
    PageContent,
    Paginator,
    RecursionTree,
    SearchBar,
    DatabaseSortSelect,
    GtMenu
  }
})
export default class ExerciseDatabase extends mixins(ExerciseListMixin) {
  categoryHint: Node[] = [];
  checkedCategoryId: number | null = null;
  currentExercise: Exercise | null = null;
  currentExerciseId = -1;
  exercisesDownloaded = false;
  isCopyModalShown = false;
  isCopying = false;
  isDropdownExpanded = false;
  isValidationInfoShown = false;
  loadingStatus: LoadingStatusName = LoadingStatus.INCOMPLETE;
  mode = SEARCH_MODES;
  pdfUrl: string;
  scaleFactor = 0.6;
  sortKey = defaultSortKey;
  test: TestModel = deepClone(EMPTY_TEST);
  sortFields: SortData[] = [
    {
      key: 'dateNewest',
      sortField: 'dateDesc',
      sortOrder: 'desc'
    },
    {
      key: 'dateOldest',
      sortField: 'date',
      sortOrder: 'asc'
    },
    {
      key: 'scoreDesc',
      sortField: 'scoreDesc',
      sortOrder: 'desc'
    },
    {
      key: 'scoreAsc',
      sortField: 'score',
      sortOrder: 'asc'
    },
    {
      key: 'timeDesc',
      sortField: 'timeDesc',
      sortOrder: 'desc'
    },
    {
      key: 'timeAsc',
      sortField: 'time',
      sortOrder: 'asc'
    }
  ];

  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('userRole') userRole: ROLES[];
  @AppStore.State('structureStatus') structureStatus: StructureStatusName;
  @AppStore.Getter(IS_DEBUG_MODE) isDebug: any;
  @AppStore.State('isFiltersActive') isFiltersActive: boolean;
  @LayoutStore.State('menu') menu: MenuModel;

  async beforeRouteEnter (to: Route, from: Route, next: Function) {
    if (from.name === RouteName.TEST_COMPOSER) {
      localStorage.removeItem('activeFacets');
    }
    next();
  }

  changeCategory (categoryId: number) {
    this.checkedCategoryId = categoryId;
    if (this.menu.newStructure) this.categoryHint = this.findParents({ children: this.menu.newStructure }, categoryId);
    this.isValidationInfoShown = false;
  }

  get isRedactorOrAdmin () {
    return isSuperUser(this.userRole);
  }

  get isNewStructureBtnShown (): boolean {
    return this.structureStatus === StructureStatus.OLD && isTeacherGroup(this.userRole);
  }

  get sortField (): string {
    return this.sortData.sortField;
  }

  get defaultSortData (): SortData {
    return this.sortFields[0];
  }

  get sortData (): SortData {
    return this.sortFields.find(field => field.key === this.sortKey) || this.defaultSortData;
  }

  async created (): Promise<void> {
    this.resetCache();
    this.setPageId(GtPageTypes.EXERCISES_DATABASE);
    const categoryKey = this.$route.params.categoryKey.replace(/,/g, '/');
    if (categoryKey !== this.categoryKey) {
      this.setCategoryKey(categoryKey);
      await this.setDefaults();
    }
    const searchedText = this.$route.query.search ?? '';
    await this.getFilteredExercises(searchedText as string, false);
    this.loadingStatus = LoadingStatus.COMPLETE;
    this.updateFilters();
  }

  @AppStore.Mutation(SET_PAGE_ID) setPageId: (id: GtPageTypes) => void
  @AppStore.Mutation(SET_CATEGORY_KEY) setCategoryKey?: any;
  @AppStore.Action(USER_NOTIFY) notify: any;
  @AppStore.Action(SET_DEFAULTS) setDefaults: any;
  @ExerciseStore.Action(FILTER_EXERCISES) sortExercises?: any;

  @Watch('exercises')
  onExercisesChanged () {
    this.exercisesDownloaded = false;
  }

  onFiltersToggleClick () {
    this.scaleFactor = this.isFiltersActive ? 0.6 : 0.8;
  }

  dropdownItemStyle (index: number) {
    return {
      marginLeft: `${(index + 1) * 35}px`
    };
  }

  findParents (node: any, searchValue: number): any {
    if (node.id === searchValue) {
      return [];
    }
    if (node.children) {
      for (const treeNode of node.children) {
        const childResult = this.findParents(treeNode, searchValue);
        if (childResult) {
          return [treeNode, ...childResult];
        }
      }
    }
  }

  toggleDropdownMenu (): void {
    this.isDropdownExpanded = !this.isDropdownExpanded;
  }

  async copyExercise () {
    if (this.categoryHint.length && this.checkedCategoryId) {
      this.closeCopyModal();
      this.isCopying = true;
      await this.$api.get<any>(EXERCISE_COPY(this.currentExerciseId, this.checkedCategoryId)).then(res => {
        if (res.data.status) {
          this.notify(this.$tc('EXERCISE_DATABASE.exercise_has_been_copied'));
          if (this.currentExercise) this.currentExercise.copied = true;
        } else {
          this.notify({
            msg: res.data.message,
            type: 'error',
            permanent: true
          });
        }
        this.isCopying = false;
      });
    } else {
      this.isValidationInfoShown = true;
    }
  }

  openCopyModal (exercise: Exercise): void {
    this.resetDropdownState();
    this.currentExerciseId = exercise.id;
    this.currentExercise = exercise;
    this.isCopyModalShown = true;
    this.categoryHint = this.findMappedCategory();
  }

  findMappedCategory (): Node[] | [] {
    for (const elem of this.menu.structureMapping) {
      if (this.currentExercise && elem.oldId === +this.currentExercise.categoryId) {
        this.checkedCategoryId = elem.newId;
        return this.findParents({ children: this.menu.newStructure }, elem.newId);
      }
    }
    return [];
  }

  resetDropdownState () {
    this.isValidationInfoShown = false;
    this.categoryHint = [];
    this.checkedCategoryId = null;
    this.isDropdownExpanded = false;
  }

  closeCopyModal (): void {
    this.isCopyModalShown = false;
  }

  getExercisesId (exercises: Exercise[]) {
    return exercises.map(exercise => +exercise.id);
  }

  async getSortedExercises (resetPage = true) {
    if (this.isFetchingEnabled) {
      this.loadingStatus = LoadingStatus.INCOMPLETE;
      if (resetPage) this.page = defaultPage;
      this.setQueryParams();
      const options: ExercisesFilters = {
        activeFacets: this.activeFacets,
        categoryKey: this.categoryKey,
        limit: this.pageLimit,
        p: this.page,
        searchQuery: '',
        sort: this.sortField
      };
      this.isSearchCleared = this.searchText === '';
      try {
        await this.sortExercises(options);
        this.loadingStatus = LoadingStatus.COMPLETE;
      } catch (e) {
        this.loadingStatus = LoadingStatus.ERROR;
      }
    }
  }

  async generatePDF () {
    const exercisesIds = this.getExercisesId(this.exercises);
    try {
      const response = await this.$apiGt.post<any>(DOWNLOAD_PDF_URI, { idExercise: exercisesIds });
      this.pdfUrl = this.$apiURL + response.data.fileUrl;
      this.exercisesDownloaded = true;
      const linkValidityTime = 20000;
      setTimeout(() => {
        this.exercisesDownloaded = false;
      }, linkValidityTime);
    } catch (e) {
      this.notify({
        msg: this.$tc('NOTIFICATIONS.something_went_wrong'),
        type: 'error'
      });
    }
  }

  async clearFilterOptions (): Promise<void> {
    this.loadingStatus = LoadingStatus.INCOMPLETE;
    await this.getFilteredExercises();
    this.loadingStatus = LoadingStatus.COMPLETE;
  }

  addToTest (exerciseToAdd: ExerciseToAdd): void {
    sessionStorage.setItem('testFromExercises', JSON.stringify(exerciseToAdd));
    this.addToSetGtmPush(exerciseToAdd, CLogLevel.METHOD);
    this.$router.push({
      name: RouteName.TEST_COMPOSER,
      params: {
        categoryKey: this.$route.params.categoryKey,
        testTypeId: TestTypeIdEnum.TEST.toString()
      },
      query: this.$route.query
    });
  }

  async updateSelectedFilters (searchText = '', resetPage = true): Promise<void> {
    this.loadingStatus = LoadingStatus.INCOMPLETE;
    await this.getFilteredExercises(searchText, resetPage);
    this.loadingStatus = LoadingStatus.COMPLETE;
  }

  closeExercisePreviewModal ():void {
    this.exercisePreViewOpened = false;
  }

  exercisePreviewOpen (exerciseVariantId: number) {
    this.showPreviewGtmPush(CLogLevel.METHOD, exerciseVariantId);
  }

  getChildCategories (child: TreeNode | null): null | TreeNode[] {
    if (child && child.children) {
      return child.children;
    }
    return null;
  }
}
